<script>
  import Button from "components/Common/Buttons/Button.svelte";
</script>

<style>
  .card {
    height: 420px;
    width: 555px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  .card-wrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .input {
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #0074c6;
    box-sizing: border-box;
    outline: none;
    transition: box-shadow 0.2s, border 0.2s;
    font-size: 18px;
    line-height: 21px;
    padding: 20px;
    width: 100%;
  }
  .field {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: 36px;
  }
  .field > input {
    width: 100%;
  }
  .input:focus {
    box-shadow: 0 0 0 1px #0074c6;
    border-color: #0074c6;
  }
  :global(input.input.error-field) {
    box-shadow: 0 0 0 1px red;
    border-color: red;
  }
  :global(input.error-field::placeholder) {
    color: red;
  }
  .helper-error {
    display: block;
    position: absolute;
    top: 67px;
    left: 10px;
    font-size: 12px;
    color: red;
  }
  .input-button {
    display: flex;
    align-items: center;
  }
  .card-info {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b7c2ce;
  }
  @media (max-width: 991px) {
    .card {
      margin: auto;
    }
  }
  @media (max-width: 767px) {
    .card {
      margin: 0;
      width: 470px;
    }
    .card-wrapper {
      padding: 20px;
    }
  }
</style>

<div class="card">
  <div class="card-wrapper">
    <div class="field">
      <input name="Inn" class="input" placeholder="ИНН ФЛ" maxlength="12" />
      <span class="helper-error" id="Inn_error" />
    </div>
    <div class="field">
      <input name="Snils" class="input" placeholder="СНИЛС" />
      <span class="helper-error" id="Snils_error" />
    </div>
    <Button type="submit">Проверить данные</Button>
    <span class="card-info">
      АО&nbsp;“Калуга Астрал” не&nbsp;использует
      Ваши&nbsp;персональные&nbsp;данные в&nbsp;иных целях, кроме проверки
      наличия&nbsp;ЭП. Проверка сертификатов, выполняется в
      УЦ&nbsp;АО&nbsp;“Калуга Астрал”, информация о&nbsp;сертификатах выданных
      в&nbsp;других УЦ, можно получить в&nbsp;
      <a href="https://digital.gov.ru/ru/" target="_blank">Минкомсвязи РФ.</a>
    </span>
  </div>
</div>
