<script>
  import WhiteButton from "components/Common/Buttons/WhiteButton.svelte";
</script>

<style>
  .footer-width {
    max-width: 1400px;
    margin: auto;
    height: 100%;
  }

  .footer-width .header {
    color: white;
    text-align: center;
  }
  .form-link {
    color: #0074c6;
  }
  .form-link:active,
  .form-link:visited,
  .form-link:link {
    text-decoration: none;
    color: #0074c6;
    text-align: center;
  }
  .form-link:hover {
    text-decoration: none;
  }
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }
  .footer-info {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    width: 50%;
  }
  .title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #fff;
    margin-bottom: 8px;
  }
  .text {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
  }
  @media (max-width: 767px) {
    .title {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 4px;
    }
    .text {
      font-size: 14px;
      line-height: 16px;
    }
  }
</style>

<div class="footer-width">
  <div class="footer-container">
    <div class="footer-info">
      <div class="title">Остались вопросы?</div>
      <div class="text">
        Свяжитесь&nbsp;с нашими специалистами для получения подробностей.
      </div>
    </div>
    <div>
      <WhiteButton>
        <a
          href="https://forms.gle/KABg5hBr9Tk2VJW49"
          class="form-link"
          target="_blank">
          Задать вопрос
        </a>
      </WhiteButton>
    </div>
  </div>
</div>
