<script>
  import Icon1 from "static/Icon1.svelte";
  import Icon2 from "static/Icon2.svelte";
  import Icon3 from "static/Icon3.svelte";
  import Icon4 from "static/Icon4.svelte";
  import Icon5 from "static/Icon5.svelte";
  import IconCheck from "static/IconCheck.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
</script>

<style>
  .header {
    text-align: center;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: #1d3f66;
  }
  .card-wrapper {
    border-radius: 12px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    margin: 60px auto;
    display: flex;
  }
  .icon {
    background: linear-gradient(251.21deg, #31a0ee 0%, #0074c6 100%);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }
  .card-wrapper.even .icon {
    border-radius: 0 12px 12px 0;
  }
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 36px 56px;
    overflow: hidden;
  }
  .card-header {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #1d3f66;
    margin-bottom: 24px;
    margin-left: 44px;
  }
  .card-text {
    display: flex;
    align-items: flex-start;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #1d3f66;
    margin-bottom: 24px;
    margin-left: 44px;
  }
  .check-icon {
    margin-left: -44px;
    margin-right: 12px;
  }
  @media (max-width: 991px) {
    .header {
      margin: 12px 0;
    }
    .card-wrapper {
      flex-direction: column;
    }
    .card-wrapper.even {
      flex-direction: column-reverse;
    }
    .icon {
      border-radius: 12px 12px 0 0;
    }
    .card-wrapper.even .icon {
      border-radius: 12px 12px 0 0;
    }
  }
  @media (max-width: 767px) {
    .header {
      margin: 12px 0;
    }
    .card-wrapper {
      flex-direction: column;
    }
    .card-wrapper.even {
      flex-direction: column-reverse;
    }
    .icon {
      border-radius: 12px 12px 0 0;
    }
    .card-wrapper.even .icon {
      border-radius: 12px 12px 0 0;
    }
  }
</style>

<h2 class="header" id="security">Как защитить себя?</h2>
<div class="max-width">
  <ContentWrapper>
    <div class="card-wrapper">
      <div class="icon">
        <Icon4 />
      </div>
      <div class="card-container">
        <div class="card-header">Проверка ЭП</div>
        <div class="card-text">
          <div>
            В&nbsp;настоящее время в&nbsp;РФ не&nbsp;существует единого сервиса
            для&nbsp;проверки, выпущенных квалифицированных сертификатов,
            тем&nbsp;не&nbsp;менее, если вы &nbsp;заподозрили неладное, вам
            следует обратиться в&nbsp;
            <a href="https://digital.gov.ru/ru/" target="_blank">
              Минкомсвязи РФ
            </a>
            , сообщить ваши фамилию имя и&nbsp;номер СНИЛС, и&nbsp;вам сообщат, какие
            сертификаты&nbsp;на&nbsp;вас оформлены, в&nbsp;каком Удостоверяющем
            центре и&nbsp;когда.
          </div>
        </div>
      </div>
    </div>
  </ContentWrapper>
</div>
<div class="max-width">
  <ContentWrapper>
    <div class="card-wrapper even">
      <div class="card-container">
        <div class="card-header">Причинен ли вам ущерб мошенниками?</div>
        <div class="card-text">
          <div class="check-icon">
            <IconCheck />
          </div>
          <div>
            Проверьте нет&nbsp;ли юр. лиц зарегистрированных на&nbsp;ваше имя,
            на&nbsp;этом сайте или сайте
            <a href="https://pb.nalog.ru/" target="_blank">налоговой</a>
            . Обратитесь в&nbsp;ИФНС, если увидите компании о&nbsp;которых
            &nbsp;знаете.
          </div>
        </div>
        <div class="card-text">
          <div class="check-icon">
            <IconCheck />
          </div>
          <div>
            Проверьте, нет&nbsp;ли&nbsp;на&nbsp;вас кредитов, запросив выписку
            из&nbsp;БКИ через
            <a href="https://www.gosuslugi.ru/329476/2" target="_blank">
              Госуслуги
            </a>
            , обратитесь в&nbsp;банк, если увидели кредит, который
            не&nbsp;брали.
          </div>
        </div>
      </div>
      <div class="icon">
        <Icon5 />
      </div>
    </div>
  </ContentWrapper>
</div>
<div class="max-width">
  <ContentWrapper>
    <div class="card-wrapper">
      <div class="icon">
        <Icon1 />
      </div>
      <div class="card-container">
        <div class="card-header">Не&nbsp;разглашайте персональные данные</div>
        <div class="card-text">
          <div class="check-icon">
            <IconCheck />
          </div>
          <div>
            Прежде чем ввести свои персональные данные на&nbsp;сайте,
            внимательно проверьте адресную строку сайта, мошенники часто
            используют практически “клоны” сайтов!
          </div>
        </div>
        <div class="card-text">
          <div class="check-icon">
            <IconCheck />
          </div>
          <div>
            Не сообщайте свои персональные данные людям, не&nbsp;убедившись, что
            эти люди имеют право у&nbsp;вас их запрашивать!
          </div>
        </div>
        <div class="card-text">
           <div class="check-icon">
             <IconCheck />
           </div>
           <div>
              Установите антивирус! На&nbsp;компьютер, планшет и даже смартфон!
              Не&nbsp;посещайте сомнительные сайты в&nbsp;интернете! Пользуйтесь услугами только сертифицированных удостоверяющих центров!
           </div>
        </div>
      </div>
    </div>
  </ContentWrapper>
</div>
<div class="max-width">
  <ContentWrapper>
    <div class="card-wrapper even">
      <div class="card-container">
        <div class="card-header">
          К каким данным стоит относиться внимательнее?
        </div>
        <div class="card-text">
          <div>
            Для получения ЭП мошенникам потребуется СНИЛС, ИНН и&nbsp;удостоверение
            личности.
          </div>
        </div>
        <div class="card-text">
          <div>
            В&nbsp;случае утери документа или если имеются основания полагать,
            что данные могли попасть&nbsp;в руки мошенников, обратитесь в&nbsp;правоохранительные
            органы или в&nbsp;органы гос. власти
            за&nbsp;заменой документа, реквизиты которого могли стать доступны
            мошенникам
          </div>
        </div>
      </div>
      <div class="icon">
        <Icon3 />
      </div>
    </div>
  </ContentWrapper>
</div>
<div class="max-width">
  <ContentWrapper>
    <div class="card-wrapper">
      <div class="icon">
        <Icon2 />
      </div>
      <div class="card-container">
        <div class="card-header">Защита от регистрации организаций</div>
        <div class="card-text">
          <div class="check-icon">
            <IconCheck />
          </div>
            <div>Чтобы обезопасить себя от мошеннических действий вы можете заполнить и предоставить в ФНС форму 38001, которая позволит запретить регистрацию юр. лиц, без Вашего личного присутствия.</div>
        </div>
        <div class="card-text">
          <div class="check-icon">
            <IconCheck />
          </div>
          <div>Для этого воспользуйтесь <a href="https://www.nalog.ru/rn77/program/5961277/" target="_blank">программой</a>, либо скачайте <a href="https://www.nalog.ru/html/sites/www.new.nalog.ru/doc/pril2_fns72_110216.pdf" target="_blank">бланк</a> с сайта <a href="https://www.nalog.ru/" target="_blank">ФНС</a>.</div>
        </div>
      </div>
    </div>
  </ContentWrapper>
</div>
