<script>
  import LeftHeader from "./HeaderComponent/LeftHeader.svelte";
  import RightHeader from "./HeaderComponent/RightHeader.svelte";
  import ContentWrapper from "components/ContentWrapper.svelte";
</script>

<style>
  header {
    position: relative;
    margin-bottom: 50px;
    background: #ebeef1;
  }
  header.top-header {
    box-shadow: none;
    margin-bottom: 0;
  }
  .container {
    height: 80px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-width {
    max-width: 1400px;
    margin: auto;
  }
</style>

<header class="top-header">
  <div class="container w-100 header-width">
    <ContentWrapper>
      <div class="row">
        <LeftHeader />
        <RightHeader />
      </div>
    </ContentWrapper>
  </div>
</header>
