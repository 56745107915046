<script>
  import ContentWrapper from "components/ContentWrapper.svelte";
</script>

<style>
  .container {
    max-width: 768px;
    margin: auto;
    padding: 24px 0 48px;
  }
  .header {
    text-align: center;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: #1d3f66;
    margin-bottom: 60px;
  }
  .help-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .help-container-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 80px;
  }
  .help-text {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #1d3f66;
  }
  .help-number {
    background: url("/Dotted.svg") no-repeat;
    font-weight: 500;
    font-size: 60px;
    line-height: 70px;
    color: #0074c6;
    border-radius: 50%;
    padding: 32px;
    margin-right: 24px;
    width: 95px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .info-text {
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
  }
  .info-title {
    color: #99a9ba;
    margin-right: 8px;
  }
  .info-data {
    color: #1d3f66;
  }
  @media (max-width: 991px) {
    .header {
      padding: 0 36px;
    }
  }
  @media (max-width: 767px) {
    .header {
      padding: 0 36px;
    }
    .info-text {
      font-size: 20px;
    }
  }
</style>

<div class="container">
  <h2 class="header" id="help">
    Что делать если на&nbsp;Вас вдруг оформлена подпись?
  </h2>
  <div class="help-container max-width">
    <ContentWrapper>
      <div class="help-container-card">
        <div class="help-number">1</div>
        <div class="help-text">
          <div class="info-container">
            <div>Срочно обратитесь в&nbsp;Удостоверяющий центр!</div>
            <div class="info-text">
              <div class="info-title">E-mail:</div>
              <div class="info-data">
                <a href="mailto:cg@astral.ru" target="_blank">cg@astral.ru</a>
              </div>
            </div>
            <div class="info-text">
              <div class="info-title">Телефон:</div>
              <div class="info-data">
                <a href="tel:+74842788999" target="_blank">8 (4842) 788-999</a>
                доб. 91
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="help-container-card">
        <div class="help-number">2</div>
        <div class="help-text">
          Сотрудники отдела предложат вам процедуру отзыва (с&nbsp;обязательной
          идентификацией вашей личности), затем разъяснят дальнейший порядок
          действий.
        </div>
      </div>
      <div class="help-container-card">
        <div class="help-number">3</div>
        <div class="help-text">
          Обратитесь в&nbsp;правоохранительные органы с&nbsp;заявлением
          о&nbsp;неправомерных/мошеннический действиях с&nbsp;вашими
          персональными данными.
        </div>
      </div>
    </ContentWrapper>
  </div>
</div>
