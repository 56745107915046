<style>
  @media (max-width: 767px) {
    .content-wrapper {
      margin: 0 16px;
    }
  }
  @media (min-width: 768px) {
    .content-wrapper {
      margin: 0 16px;
    }
  }
  @media (min-width: 992px) {
    .content-wrapper {
      margin: 0;
    }
  }
  @media (min-width: 1200px) {
    .content-wrapper {
      margin: 0 40px;
    }
  }
  @media (min-width: 1400px) {
    .content-wrapper {
      margin: 0 40px;
    }
  }
</style>

<div class="content-wrapper">
  <slot />
</div>
