<script>
  import FooterComponent from "components/Footer/FooterComponent.svelte";
</script>

<style>
  .container {
    background: linear-gradient(207.01deg, #0074c6 8.64%, #31a0ee 90.85%);
    flex: 0 0 auto;
  }
  .divider {
  }
</style>

<div class="container w-100">
  <FooterComponent />
</div>
